import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import CustomAttrs from "@mixins/custom-attributes"
import Include from "@mixins/include"
import axios from "axios"
import { FieldExcludeAttr } from "@mixins/fields/types"
import SearchMixin from "@mixins/search"
import router from "@router"

export const state = {
  ...BaseState,
  totalCompaniesCount: null,
  toggleFilterMenu: false,
  transitionDrawer: null,
  // Relations
  savedFilters: null,
  smartFilters: null,
  integrationForms: null,
  allTags: [],
  // Sections
  cacheSections: [],
  reportSections: null,
  // Import State
  activeImport: false,
}

export const getters = {
  ...BaseGetters,
  reportsTableData: (state) => {
    // Build table data object for admin network settings
    return buildTableData(state)
  },
  totalCompaniesCount: (state) => {
    return state.totalCompaniesCount
  },
  toggleFilterMenu: (state) => {
    return state.toggleFilterMenu
  },
  primaryColor: (state) => {
    return (
      _.get(state.model, "primary_color", null) ||
      _.get(state.model, "owner_group_brand_color", null) ||
      "#1c1836"
    )
  },
  allTags: (state) => {
    return state.allTags
  },

  // ===
  // Filters
  // ===

  // Saved filters are all filters (group and report-level)
  // associated with this report.
  savedFilters: (state) => {
    return state.savedFilters
  },
  savedFilter: (state) => (options) => {
    return _.get(state, "savedFilters", []).find(
      (record) => record.id === _.toInteger(options.id)
    )
  },
  savedFilterData: (state) => (options) => {
    if (_.isEmpty(state.savedFilters)) {
      return []
    }

    const filterData = _.get(
      state.savedFilters.find(
        (record) => record.id === _.toInteger(options.id)
      ),
      "filter_data"
    )

    return filterData ? JSON.parse(filterData) : null
  },

  // Smart filters are group-level saved filters that have
  // a ModelFilterRelation to the report.
  smartFilters: (state) => {
    return state.smartFilters
  },

  // ===
  // Forms
  // ===

  integrationForms: (state) => {
    return state.integrationForms
  },
  integrationForm: (state) => (options) => {
    return state.integrationForms.find(
      (record) => record.id === options.integration_form_id
    )
  },

  // ===
  // Sections
  // ===

  reportSections: (state) => {
    return state.reportSections
  },
  cacheSections: (state) => {
    return getCacheSections(state)
  },
  reportHasListTab: (state) => {
    return _.find(state.cacheSections, { key: "company_list_1" })
  },
  defaultSectionRoute: (state, getters, rootState, rootGetters) => {
    const section = _.get(state.cacheSections, "[0]")
    const shareToken = rootGetters["auth/shareToken"]

    return section
      ? {
          name: `${shareToken ? "share-" : ""}${section.path}`,
          params: {
            id: _.get(state.model, "id"),
            saved_filter_id: section.saved_filter_id,
            integration_form_id: section.integration_form_id,
            secret: shareToken,
          },
        }
      : null
  },
  transitionDrawer: (state) => {
    return _.get(state, "transitionDrawer", null)
  },
}

export const actions = {
  ...BaseActions,
  createReportWithTransition: ({
    commit,
    dispatch,
    state,
    getters,
    rootGetters,
  }) => {
    if (rootGetters["auth/activeGroup"].srm_enabled) {
      dispatch("createReport", {
        name: "New Report",
        group_id: rootGetters["auth/activeGroup"].id,
        user_id: rootGetters["auth/activeUser"].id,
      })
        .then((newReport) => {
          _.set(newReport, "isNew", true)
          _.set(newReport, "can_edit", true)

          // Transition to new report...
          router
            .push({
              name: "report-view",
              params: {
                id: _.get(newReport, "id"),
                permalink: _.get(newReport, "default_context_permalink"),
                bypassRedirect: true,
              },
            })
            .catch((e) => {})

          // And open the settings drawer overview tab
          dispatch("reports/setTransitionDrawer", "ReportDrawerOverview", {
            root: true,
          })
        })
        .catch((e) => console.error(e))
    }
  },
  createReport: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post("reports", params)
        .then((response) => {
          const report = _.get(response, "data.report")
          commit("SET_INCLUDE", response.data)
          commit("TOGGLE_WORKFLOW_BUILDER", true)
          dispatch(
            "amplitude/logEvent",
            {
              name: "Create Report",
              properties: {
                id: report.id,
                name: report.name,
                group_id: report.group_id,
                user_id: report.user_id,
                duplicate_worker_id: report.duplicate_worker_id,
                created_at: report.created_at,
              },
            },
            { root: true }
          )
          resolve(report)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  duplicateReport: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post("snowflakes/duplicate_report", params)
        .then((response) => {
          const report = _.get(response, "data.report")
          commit("TOGGLE_WORKFLOW_BUILDER", true)
          resolve(report)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  toggleFilterMenu: ({ commit }, isActive) => {
    commit("TOGGLE_FILTER_MENU", isActive)
  },
  customShow: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get(`reports/${payload.id}`, {
          params: {
            q: JSON.stringify({
              custom_attrs: [
                CustomAttrs.canEdit("report"),
                CustomAttrs.reportImportState(),
                CustomAttrs.reportFollowState(),
                CustomAttrs.reportFollowCount(),
              ],
              include: Include.models([
                ["relation", "smart_filters"],
                ["relation", "saved_filters"],
                ["relation", "integration_forms"],
              ]),
            }),
          },
        })
        .then((response) => {
          commit("SET_INCLUDE", response.data)
          commit("SET_LOADING", false)
          resolve(response.data.report)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
        .finally(() => {
          dispatch("setTotalCompaniesCount")
        })
    })
  },
  reloadReport: ({ dispatch, getters }) => {
    const id = getters.model && getters.model.id
    if (!id) {
      console.error("Unable to reload report. Id not present")
    }
    dispatch("customShow", { id })
  },
  resetCachedStates: ({ commit }) => {
    commit("RESET_CACHED_STATES")
  },
  findCacheOrLoadCustomShow: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      const cachedReport = getters["model"]
      if (
        !_.isEmpty(cachedReport) &&
        _.isEqual(_.toNumber(cachedReport.id), _.toNumber(payload.id))
      ) {
        resolve(cachedReport)
      } else {
        dispatch("customShow", payload)
          .then((report) => {
            dispatch("fetchReportSectionsCache", report.id)
              .then(() => {
                resolve(report)
              })
              .catch(() => {
                reject()
              })
          })
          .catch(() => {
            reject()
          })
      }
    })
  },
  setTotalCompaniesCount: ({ commit, dispatch, getters }, count) => {
    if (_.isNumber(count)) {
      commit("SET_TOTAL_COMPANIES_COUNT", count)
    } else if (_.get(getters["model"], "id")) {
      dispatch("fetchAllReportCompaniesCount", getters["model"]).then(
        (count) => {
          commit("SET_TOTAL_COMPANIES_COUNT", count)
        }
      )
    }
  },
  fetchAllReportCompaniesCount: ({}, report) => {
    return new Promise((resolve, reject) => {
      axios
        .get("snowflakes/report_actions/all_report_companies", {
          params: {
            report_id: report.id,
            q: SearchMixin.buildSearchObject({
              indices: [`g${report.group_id}_companies`],
              includes: [],
              filters: [],
              sorts: [],
              keywords: [],
              per_page: 1,
              page: 1,
            }),
          },
        })
        .then((resp) => {
          resolve(_.get(resp, "data.meta.total_entries"))
        })
        .catch((e) => {
          console.error(e)
          reject(null)
        })
    })
  },
  fetchImportState: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get(`reports/${payload.id}`, payload.params)
        .then((r) => {
          let ai = _.get(r, "data.report.import_state.active_import", false)
          commit("SET_ACTIVE_IMPORT", ai)
          resolve(ai)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  fetchTags: ({ commit, dispatch, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get(
          `snowflakes/report_actions/get_report_tags?group_id=${rootGetters["auth/activeGroup"].id}`
        )
        .then((resp) => {
          let tags = _.get(resp, "data.tags", [])
          commit("SET_ALL_TAGS", tags)
          resolve(tags)
        })
        .catch((e) => {
          commit("SET_ALL_TAGS", [])
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },

  // ===
  // Filters
  // ===

  createReportFilter: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post("saved_filters", { saved_filter: params })
        .then((resp) => {
          resolve(_.get(resp, "data.saved_filter"))
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  handleFilterUpdate: ({ commit, dispatch }, params) => {
    const filter = params.filter
    // If label changed, update sections cache then refresh data.
    if (params.labelChanged) {
      dispatch("updateSection", {
        report_id: filter.report_id,
        section: {
          name: filter.label,
          key: `filter_${filter.id}`,
          saved_filter_id: filter.id,
          path: "report-view",
        },
      }).then(() => {
        dispatch("customShow", {
          id: filter.report_id,
        })
      })
    } else {
      // Otherwise, refresh report data.
      dispatch("customShow", {
        id: filter.report_id,
      })
    }
  },

  // ===
  // Sections
  // ===

  fetchReportSectionsCache: ({ commit, rootGetters }, reportId) => {
    commit("SET_LOADING", true)

    return axios
      .get(`/cache?id=report_section_caches&cache[report_id]=${reportId}`)
      .then((response) => {
        const sections = JSON.parse(_.get(response, "data.value.sections", []))
        const shareToken = rootGetters["auth/shareToken"]

        commit("SET_CACHE", {
          cache: "reportSectionCache",
          value: JSON.stringify(sections),
        })
        commit("SET_REPORT_SECTIONS", {
          sections: sections,
          shareToken,
        })
        commit("INCREMENT_RELOAD_KEY")
        commit("SET_LOADING", false)

        return sections
      })
      .catch((error) => {
        console.error(error)
        commit("SET_LOADING", false)
      })
  },
  addSection: ({ commit, dispatch, state, getters }, params) => {
    // Prevent static list from being added more than once.
    if (
      _.get(params, "section.key") == "company_list_1" &&
      getters.reportHasListTab
    ) {
      return
    }

    commit("ADD_SECTION_TO_CACHE", params.section)

    return new Promise((resolve, reject) => {
      dispatch("putReportSectionsCache", {
        id: "report_section_caches",
        cache: {
          report_id: params.report_id,
        },
        sections: state.cacheSections,
      })
        .then((resp) => {
          dispatch("customShow", { id: params.report_id })
            .then(() => {
              resolve(resp)
            })
            .finally(() => {
              dispatch("app/reloadBaseSith", null, { root: true })
            })
        })
        .catch((e) => reject(e))
    })
  },
  removeSection: ({ commit, dispatch, state }, params) => {
    commit("REMOVE_SECTION_FROM_CACHE", params.saved_filter_id)
    return new Promise((resolve, reject) => {
      dispatch("putReportSectionsCache", {
        id: "report_section_caches",
        cache: { report_id: params.report_id },
        sections: state.cacheSections,
      })
        .then((resp) => {
          dispatch("findCacheOrLoadCustomShow", {
            id: params.report_id,
          })
            .then(() => {
              resolve(resp)
            })
            .finally(() => {
              dispatch("app/reloadBaseSith", null, { root: true })
            })
        })
        .catch((e) => reject(e))
    })
  },
  updateSection: ({ commit, dispatch, state }, params) => {
    commit("UPDATE_SECTION_IN_CACHE", params.section)

    return new Promise((resolve, reject) => {
      dispatch("putReportSectionsCache", {
        id: "report_section_caches",
        cache: { report_id: params.report_id },
        sections: state.cacheSections,
      })
        .then((r) => resolve(r))
        .catch((e) => reject(e))
    })
  },
  putReportSectionsCache: ({ commit, state, rootGetters }, params) => {
    commit("SET_LOADING", true)

    return new Promise((resolve, reject) => {
      axios
        .put("cache?id=report_section_caches", {
          cache: {
            report_id: params.cache.report_id,
            value: JSON.stringify({
              sections: JSON.stringify(params.sections),
            }),
          },
        })
        .then((response) => {
          const sections = _.get(response, "data.value.sections", [])
          commit("SET_CACHE", {
            cache: "reportSectionCache",
            value: sections,
          })
          commit("SET_REPORT_SECTIONS", {
            sections: JSON.parse(sections),
            shareToken: rootGetters["auth/shareToken"],
          })
          commit("INCREMENT_RELOAD_KEY")
          commit("SET_LOADING", false)
          resolve(sections)
        })
        .catch((error) => {
          commit("SET_LOADING", false)
          console.error(error)
          reject(error)
        })
    })
  },
  setTransitionDrawer: ({ commit }, transitionDrawer) => {
    commit("SET_TRANSITION_DRAWER", transitionDrawer)
  },

  // ===

  putReportSmartFilters: ({ commit, state }, params) => {
    commit("SET_SMART_FILTERS", params)
  },
  bulkAddCompanies: ({ commit }, params) => {
    return axios.put("snowflakes/report_actions/add", params)
  },
  fetchReports: ({ commit }, payload) => {
    commit("SET_LOADING", true)

    const contextObject = {
      indices: ["reports"],
      includes: [
        "name",
        "user_id",
        "group_id",
        "model_type",
        "contact_email",
        "owner_network_name",
        "owner_group_name",
        "app_context",
        "default_section_route",
        "default_context_permalink",
        "primary_color",
      ],
    }
    contextObject.per_page = _.get(payload, "perPage", 50)
    contextObject.filters = _.get(payload, "filters", [])

    return new Promise((resolve, reject) => {
      axios
        .get("search", {
          params: { q: SearchMixin.buildSearchObject(contextObject) },
        })
        .then((res) => {
          commit("SET_ALL", res)
        })
        .catch((e) => {
          console.error(e)
          commit("SET_LOADING", false)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          resolve()
        })
    })
  },
  fetchReportSharedFieldsCount: ({ commit }, payload) => {
    return axios
      .get(
        `/cache?id=group_report_companies_index_cache&cache[group_id]=${payload.group_id}&cache[report_id]=${payload.report_id}`
      )
      .then((response) => {
        const fieldMap = JSON.parse(_.get(response, "data.value.fields", []))
        return _.get(fieldMap, "length", null)
      })
      .catch((error) => {
        console.error(error)
      })
  },
}

export const mutations = {
  ...BaseMutations,
  TOGGLE_FILTER_MENU(state, isActive) {
    state.toggleFilterMenu = isActive
  },
  SET_SMART_FILTERS(state, payload) {
    state.smartFilters = payload
  },
  SET_INCLUDE(state, payload) {
    state.model = _.get(payload, "report", null)
    state.savedFilters = _.get(payload, "saved_filters", null)
    state.smartFilters = _.get(payload, "smart_filters", null)
    state.integrationForms = _.get(payload, "integration_forms", null)
  },
  RESET_CACHED_STATES(state) {
    state.model = null
    state.totalCompaniesCount = null
    state.toggleFilterMenu = false
    // Relations
    state.savedFilters = null
    state.smartFilters = null
    state.integrationForms = null
    state.allTags = []
    // Sections
    state.cacheSections = []
    state.reportSections = null
  },
  TOGGLE_WORKFLOW_BUILDER(state, shouldOpen) {
    state.openWorkflowBuilder = shouldOpen
  },

  // ===
  // Sections
  // ===

  SET_REPORT_SECTIONS(state, { sections, shareToken }) {
    state.reportSections = sections
  },
  SET_CACHE: (state, params) => {
    state.cacheSections = JSON.parse(params.value)
  },
  ADD_SECTION_TO_CACHE: (state, section) => {
    state.cacheSections.push(section)
  },
  REMOVE_SECTION_FROM_CACHE: (state, savedFilterId) => {
    _.remove(state.cacheSections, function (s) {
      return _.get(s, "saved_filter_id") === savedFilterId
    })
  },
  UPDATE_SECTION_IN_CACHE: (state, updatedSection) => {
    state.cacheSections = _.map(state.cacheSections, (section) => {
      return section.saved_filter_id === updatedSection.saved_filter_id
        ? updatedSection
        : section
    })
  },
  ADD_SECTIONS_TO_CACHE: (state, sections) => {
    sections.forEach((section) => {
      state.cacheSections.push(section)
    })
  },
  REMOVE_SECTIONS_FROM_CACHE: (state, keys) => {
    _.remove(state.cacheSections, function (s) {
      return keys.includes(_.get(s, "key"))
    })
  },
  SET_TOTAL_COMPANIES_COUNT: (state, count) => {
    state.totalCompaniesCount = count
  },
  SET_TRANSITION_DRAWER: (state, component) => {
    state.transitionDrawer = component
  },
  SET_ACTIVE_IMPORT: (state, ai) => {
    state.activeImport = ai
  },
  SET_ALL_TAGS: (state, tags) => {
    state.allTags = tags
  },
}

// ===
// Private helpers
// ===

function buildTableData(state) {
  const items = []
  const baseObject = {
    selected: [],
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "name",
      },
      { text: "Last updated", value: "updated_at" },
    ],
    items: [],
  }

  if (state.all.length > 0) {
    state.all.forEach((model) => {
      items.push({
        type: model._index,
        id: model.id,
        name: model.name,
        updated_at: model.updated_at,
        shared: "-", // Implement later
      })
    })
  }

  baseObject.items = items
  return baseObject
}

function getCacheSections(state) {
  let cache = state.cacheSections

  if (_.isArray(cache) && !_.isEmpty(cache)) {
    cache = Object.values(cache).map((key) => {
      return key.key
    })

    if (!state.reportSections) {
      return []
    }

    const sections = state.reportSections
      .filter(
        (f) =>
          filterByExcludeAttrs(f) &&
          filterByCache(f, cache) &&
          !filterByCache(f, ["dashboard_1", "activity"])
      )
      .sort((a, b) => cache.indexOf(a.key) - cache.indexOf(b.key))

    return sections
  }
}

function filterByExcludeAttrs(section) {
  return !FieldExcludeAttr.includes(`${section.attribute}`)
}

function filterByCache(section, cache) {
  return cache.includes(section.key)
}
